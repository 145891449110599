import React, { useState } from 'react';
import { PlanProps } from 'app/components/types/ResponsiveResearch';
import { Fail, Done } from 'assets/images';
import { Button } from 'app/components/Button';
import { useNavigate } from 'react-router-dom';

export const PlanBox = ({ props }: { props: PlanProps }) => {
  const navigate = useNavigate();
  const [priceColor, setPriceColor] = useState('#989a9f');

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handlePriceHover = () => {
    // Randomly choose between red and green
    const colors = ['#22c55e', '#ef4444'];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setPriceColor(randomColor);
  };

  const handlePriceLeave = () => {
    setPriceColor('#989a9f'); // Reset to original color
  };

  return (
    <div className="gap-y-4 lg:gap-y-8 sm:w-[350px] sm:h-[450px] lg:h-[580px] lg:w-[400px] 
      border border-[#1d2838] rounded-xl flex flex-col items-start justify-center p-8 lg:px-10 
      lg:py-8 bg-[#0e121a80] transition-all duration-300 ease-in-out
      hover:border-[#3d4e6a] hover:shadow-[0_0_15px_rgba(61,78,106,0.5)]">
      <div className="text-white text-xl lg:text-2xl xl:text-3xl font-bold border-b-2 border-[#192331] w-full pb-4">
        {props.title}
      </div>
      <div 
        className="font-bold text-2xl lg:text-3xl xl:text-4xl mb-2 transition-colors duration-300"
        style={{ color: priceColor }}
        onMouseEnter={handlePriceHover}
        onMouseLeave={handlePriceLeave}
      >
        {USDollar.format(Number(props.price))}
        <span className="text-base">/month</span>
      </div>
      <div>
        <div className="flex flex-col items-start justify-start gap-y-6 text-white">
          {props.features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-start gap-x-2 md:gap-x-3"
            >
              <div
                className={`${feature.available ? 'w-3 h-3' : 'w-2 h-2 -mt-1'}`}
              >
                <img
                  src={feature.available ? Done : Fail}
                  alt="w-full h-full"
                />
              </div>
              <div
                className={`font-medium text-base lg:text-lg xl:text-lg ${
                  feature.available ? 'text-[#d1d1d1]' : 'text-[#7a7f8e]'
                }`}
              >
                {feature.feature}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center mt-2">
        <Button
          size="small"
          onClick={() => {
            navigate('/home');
          }}
        >
          Stripe Payment
        </Button>
      </div>
    </div>
  );
};