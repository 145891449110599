import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state?.defaultLayoutState || initialState;

export const selectDefaultLayoutState = createSelector(
  [selectSlice],
  state => state,
);

export const selectToastMessage = createSelector(
  [selectDefaultLayoutState],
  state => state.appMessage,
);

export const selectUser = createSelector(
  [selectDefaultLayoutState],
  state => state.user,
);
