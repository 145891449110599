import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { IProps } from 'app/components/types/PublicPageTypes';
import MainLayout from 'app/pages/MainLayout';

function PublicPages({ route }: IProps) {
  const location = useLocation();
  const { path, component: Component } = route;
  const token = localStorage.getItem('token');
  return (
    <MainLayout>
      <Component />
    </MainLayout>
  );
}

export default PublicPages;
