import { Home } from 'app/pages/HomePage';
import LandingPage from 'app/pages/LandingPage';
import ProfilePage from 'app/pages/ProfilePage';
import SubscriptionPage from 'app/pages/SubscriptionPage';
import StripePage from 'app/pages/StripePage';
import { IRoute } from './types';
import { RouteNames } from 'utils/constants';
import VerifyEmail from 'app/pages/VerifyEmail';
import HubIframeComponent from 'app/pages/Hub';
import PlotlyIframeComponent from '../../app/pages/Plotly';
import LogoutPage from 'app/pages/Logout';

export const routes: IRoute[] = [
  {
    path: '/',
    component: LandingPage,
    isAuthenticated: false,
    exact: true,
  },
  {
    path: '/home',
    component: Home,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Home,
  },
  {
    path: '/profile',
    component: ProfilePage,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Profile,
  },
  {
    path: '/subscribe',
    component: SubscriptionPage,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Subscribe,
  },
  {
    path: '/app',
    component: PlotlyIframeComponent,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.App,
  },
  {
    path: '/app/*',
    component: PlotlyIframeComponent,
    isAuthenticated: true,
    exact: false,
    label: RouteNames.App,
  },
  {
    path: '/stripe',
    component: StripePage,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Stripe,
  },
  {
    path: '/hub',
    component: HubIframeComponent,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Hub,
  },
  {
    path: '/unverified',
    component: VerifyEmail,
    isAuthenticated: false,
    exact: true,
  },
  {
    path: '/logout',
    component: LogoutPage,
    isAuthenticated: false,
    exact: true,
  },
];
