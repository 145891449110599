import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import FontFaceObserver from 'fontfaceobserver';
import './index.css';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';

import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';
import { Navigate } from 'react-router-dom';
import { getConfig } from 'utils/config';
// Initialize languages
import './locales/i18n';
import { cache } from 'webpack';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Roboto', {});

// When Inter is loaded, add a font-family using Inter to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

const store = configureAppStore();

const config = getConfig();

const onRedirectCallback = appState => {
  return (
    <Navigate
      to={
        appState && appState.returnTo
          ? appState.returnTo
          : window.location.pathname
      }
    />
  );
};
const providerConfig = {
  domain: process.env.REACT_APP_DOMAIN!,
  clientId: process.env.REACT_APP_CLIENTID!,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: `${process.env.REACT_APP_BASE || 'https://sov.ai'}${
      window.location.pathname === '/app' ? '/app' : '/home'
    }`,
    ...(config.audience ? { audience: config.audience } : null),
  },
  cacheLocation: 'localstorage' as CacheLocation,
};
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <Auth0Provider {...providerConfig} skipRedirectCallback={window.location.pathname === '/hub'}>
          <App />
        </Auth0Provider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
