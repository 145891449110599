import React from "react";

type ContentBoxProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ContentBox = ({ children, className }: ContentBoxProps) => {
  return (
    <div
      className={`w-full h-full border border-[#775dda] rounded-3xl flex flex-col items-start justify-center p-8 bg-[#11161f] ${className}`}
    >
      {children}
    </div>
  );
};
