import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PlotlyIframeComponent = () => {
  const [src, setSrc] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    let token = localStorage.getItem('token');
    token = token?.replace(/['"]+/g, '') ?? null;

    const updateIframeSrc = (token: string) => {
      const newPath = location.pathname.replace('/app', '');
      setSrc(`https://app.sov.ai${newPath}?identity=${token}`);
    };

    if (token) {
      updateIframeSrc(token);
    } else {
      getAccessTokenSilently().then(networkToken => {
        if (networkToken) {
          localStorage.setItem('token', networkToken);
          updateIframeSrc(networkToken);
        }
      });
    }

    const handleMessage = (event) => {
      if (event.origin === 'https://app.sov.ai') {
        const { pathname } = new URL(event.data);
        window.history.pushState({}, '', `/app${pathname}`);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [getAccessTokenSilently, location]);

  return src ? (
    <iframe
      src={src}
      title="SovAI Dash"
      style={{ height: '100vh' }}
      height="100%"
      width="100%"
    ></iframe>
  ) : (
    <div>Loading...</div>
  );
};

export default PlotlyIframeComponent;

