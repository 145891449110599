/**
 * Routes file to backend endpoints, always fnish routes with out slash symbol
 */

const microServiceBases = {
  userService: '/user_service',
};

const bases = {
  user: `${microServiceBases.userService}/user`,
  product: `${microServiceBases.userService}/product`,
  subscribe: `${microServiceBases.userService}/subscription`,
  invoice: `${microServiceBases.userService}/invoice`,
  customer_portal: `${microServiceBases.userService}/customer_portal`,
};

const user = {
  create: `${bases.user}`,
  login: `${bases.user}/login`,
  signup: `${bases.user}/signup`,
  getAccount: `${bases.user}/account`,
  activateAccount: `${bases.user}/activate-account`,
  updateAccount: `${bases.user}/update-account`,
  forgetPassword: `${bases.user}/forget-password`,
};

const profile = {
  apiKey: `${microServiceBases.userService}/token`,
};
const product = {
  list: `${bases.product}`,
};

const subscribe = {
  list: `${bases.subscribe}`,
  get_invoice: `${bases.invoice}`,
  customer_portal: `${bases.customer_portal}`,
};

const routes = {
  user,
  profile,
  product,
  subscribe,
};

export default routes;
