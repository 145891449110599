export const API_ROUTE = `https://api.sov.ai`;

export const CORPORATE_PRODUCT_ID = 'prod_QamhXPXNra396J';

export enum ROUTES {
  home = '/home',
  app = '/app',
  subscribe = '/subscribe',
  profile = '/profile',
  stripe = '/stripe',
  logout = '/logout',
}

export enum RouteNames {
  Home = 'Home',
  Subscribe = 'Subscribe',
  Profile = 'Profile',
  Stripe = 'Stripe',
  App = 'App',
  Hub = 'Hub',
}

export enum HexColorOpacity {
  ZERO = '00',
  FIVE = '0C',
  TEN = '19',
  FIFTEEN = '26',
  TWENTY = '33',
  TWENTY_FIVE = '3F',
  THIRTY = '4C',
  THIRTY_FIVE = '59',
  FOURTY = '66',
  FOURTY_FIVE = '72',
  FIFTY = '7F',
  FIFTY_FIVE = '8C',
  SIXTY = '99',
  SIXTY_FIVE = 'A5',
  SEVENTY = 'B2',
  SEVENTY_FIVE = 'BF',
  EIGHTY = 'CC',
  EIGHTY_FIVE = 'D8',
  NINETY = 'E5',
  NINETY_FIVE = 'F2',
  HUNDRED = 'FF',
}
