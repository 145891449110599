import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { ProductsState } from './types';

const selectSlice = (state: RootState) => state?.products || initialState;

export const selectProducts: (state: RootState) => ProductsState =
  createSelector([selectSlice], state => state);

// export const selectSample = createSelector([selectSlice],state=>)

export const selectProductPagination = createSelector(
  [selectProducts],
  state => state.productPagination,
);

export const selectAllProducts = createSelector(
  [selectProducts],
  state => state.result,
);

export const selectIsProductLoadMoreDisabled = createSelector(
  [selectProducts],
  state => {
    const { productPagination, isProductLoading } = state;
    const isTotalLimit =
      productPagination?.limit &&
      productPagination?.offset &&
      productPagination?.total
        ? productPagination?.limit * productPagination?.offset >=
          productPagination?.total
        : true;
    return isTotalLimit || isProductLoading;
  },
);

export const selectGettingProducts = createSelector(
  [selectProducts],
  state => state.isProductLoading,
);

export const selectAllSubscriptions = createSelector(
  [selectProducts],
  state => state.subscriptions,
);
