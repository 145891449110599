import React, { useState } from 'react';
import { Button } from 'app/components/Button';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { ISubscription } from 'app/Model/subscription';
import { Link } from 'react-router-dom';
import { popUpModal } from '../OpenModal';
import { useProductSlice } from 'app/pages/HomePage/slice';
import { PopUpModalProps } from '../types/PopUpModalTypes';
import { CORPORATE_PRODUCT_ID } from 'utils/constants';

const banner = require('assets/images/banner.jpg');

interface IProps {
  sub: ISubscription;
  isSubLoading?: boolean;
  isTrial?: boolean;
}

function isValidImage(url, callback) {
  const img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export default function RenderSubscription(props: IProps) {
  const { actions } = useProductSlice();
  const { user } = useAuth0();

  const dispatch = useDispatch();

  const [useProductImage, setUseProductImage] = useState(false);
  if (props.sub.price.product.image) {
    isValidImage(props.sub.price.product.image, isValid => {
      if (isValid) {
        setUseProductImage(true);
      }
    });
  }

  const [reSubModal, setReSubModal] = useState(false);
  const handleReSub = () => {
    setReSubModal(true);
  };

  const handleReSubscription = () => {
    dispatch(
      actions.reSubscribeToProduct({
        id: props.sub.id,
      }),
    );
  };

  const handleClose = () => {
    setReSubModal(false);
  };

  const reSubModalContent: PopUpModalProps = {
    open: reSubModal,
    content: {
      title: 'ReSubscribe',
      description: 'Are you sure you want to resubscribe?',
    },
    handleClose: handleClose,
    handleAction: handleReSubscription,
    actionName: 'Yes',
  };
  return (
    <div className="w-full py-4 px-6 bg-[#11161f] border border-[#775dda] px-4 pt-4 text-gray-800 rounded-2xl relative hover:bg-[#161e2b]">
      {popUpModal(reSubModalContent)}
      <div className="gap-y-4 flex flex-col items-center justify-center ">
        <div className="w-full flex flex-grow flex-row justify-start items-center mt-6">
          <div className="w-2/5 rounded-md overflow-hidden">
            <img
              src={useProductImage ? props.sub.price.product.image : banner}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-xl font-bold text-[#f8f8f8] ml-4">
            {`${props.sub?.price.product?.title}`}
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-end gap-5 absolute top-4 right-4">
          <div className="flex flex-row items-center">
            <div className="text-white text-sm font-medium">
              {props.sub.cancel_at_period_end
                ? 'Expiring'
                : props.sub?.status
                    .replaceAll('_', ' ')
                    .replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      );
                    })}
            </div>
          </div>
          <div
            className={`w-[20px] h-[20px] rounded-full ${
              props.sub?.status === 'past_due' ||
              props.sub?.status === 'unpaid' ||
              props.sub?.status === 'incomplete' ||
              props.sub.cancel_at_period_end
                ? 'bg-[#ab530a]'
                : props.sub?.status === 'active'
                ? 'bg-[#298d2e]'
                : 'bg-[#99282a]'
            }`}
          />
        </div>
        <div className="gap-y-4 w-full flex flex-col px-2">
          <div className="text-[#f8f8f8] text-xl font-normal">
            Billed{' '}
            {props.sub?.price.recurring_interval == 'month'
              ? 'Monthly'
              : 'Yearly'}
          </div>
          <div className="text-[#f8f8f8] text-2xl lg:text-3xl xl:text-4xl ">
            ${props.sub?.price.unit_amount / 100}
            <span className="text-base text-[#989a9f]">/month</span>
          </div>
          <div className="text-sm font-normal text-[#827c7c]">
            {props.sub.cancel_at_period_end ? 'Expires on' : 'Renews on'}{' '}
            {new Date(props.sub?.expires).toDateString()}
          </div>
          {props.sub.price.product_id === CORPORATE_PRODUCT_ID ? (
            <div className="flex w-full gap-4">
              <Link
                className="w-full"
                style={{ color: '#f8f8f8', textDecoration: 'none' }}
                to="https://sov.ai/app"
                target="_blank"
              >
                <Button className="w-full h-full py-3 text-sm" type="unfilled">
                  App
                </Button>
              </Link>
              <Link
                className="w-full"
                style={{ color: '#f8f8f8', textDecoration: 'none' }}
                to="https://sov.ai/hub"
                target="_blank"
              >
                <Button className="w-full h-full py-3 text-sm" type="unfilled">
                  Hub
                </Button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`${
          !props.sub.cancel_at_period_end
            ? 'hidden'
            : 'z-10 w-full text-base font-medium flex flex-row items-center justify-center gap-4 mt-4'
        }  `}
      >
        <Button
          className="w-3/5 py-3 text-[#f8f8f8]"
          type="filled"
          onClick={handleReSub}
        >
          ReSubscribe
        </Button>
      </div>
    </div>
  );
}
