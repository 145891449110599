import * as React from 'react';
import { SovAILogo } from 'assets/images';
import { useAuth0 } from '@auth0/auth0-react';
import { IListMenu } from './types';
import { routes } from 'utils/routes';
import { IRoute } from 'utils/routes/types';
import { ROUTES } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { Button } from 'app/components/Button';

const menuRoutes = [ROUTES.home, ROUTES.subscribe, ROUTES.profile];

export default function SideBar() {
  const { isLoading } = useAuth0();
  const navigate = useNavigate();
  const [listMenu, setListMenu] = React.useState<IListMenu[]>([]);
  React.useEffect(() => {
    const list: IListMenu[] = routes.reduce(
      (accumulator: IListMenu[], currentValue: IRoute) => {
        if (menuRoutes.includes(currentValue.path as ROUTES))
          accumulator.push({
            route: currentValue.path,
            icon: currentValue?.icon,
            label: currentValue?.label,
          });
        return accumulator;
      },
      [],
    );

    setListMenu(list);
  }, []);
  const handleRoute = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    window.location.href = '/logout';
  };
  return isLoading ? (
    <></>
  ) : (
    <div className="hidden lg:flex top-0 left-0 max-w-[240px] w-60 h-full bg-sidebar-gradient">
      <div className="w-full flex flex-col items-center">
        <div
          className="w-full flex flex-row items-center justify-start pt-8 pl-2 cursor-pointer"
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={SovAILogo} alt="SovAI Logo" className="object-cover w-40" />
        </div>
        <div className="w-full flex flex-col grow items-start justify-start pt-8 pl-4">
          {listMenu.map(({ icon: Icon, ...value }: IListMenu) => (
            <div
              key={value.route}
              onClick={() => {
                handleRoute(value.route);
              }}
              className="cursor-pointer text-white font-bold text-base py-6"
            >
              {value.label}
            </div>
          ))}
        </div>

        <div className="w-full px-4 pb-8">
          <Button size="unset" className="w-full py-2" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}
