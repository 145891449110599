import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { IRoute } from '../utils/routes/types';
import { routes } from '../utils/routes';
import { PageNotFound } from './pages/404';
import { AuthenticationGuard } from './components/Authenticated';
import PublicPages from 'app/components/PublicPage';
import GoToTopButton from 'app/components/GoToTop';

export function App() {
  const { i18n } = useTranslation();
  return (
    <>
      <GoToTopButton />
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - SOVAI"
          defaultTitle="SOVAI"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="" />
        </Helmet>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          {routes.map((route: IRoute) => {
            let body: JSX.Element;
            if (route.isAuthenticated) {
              body = <AuthenticationGuard component={route.component} />;
            } else {
              body = <PublicPages route={route} />;
            }
            return (
              <Route key={uuidv4()} path={route.path} element={<>{body}</>} />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
}
