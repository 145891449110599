import React from 'react';
import { useDefaultLayoutSlice } from './slice';
import { useAuth0 } from '@auth0/auth0-react';
import { httpClient } from 'app/API/httpClient';
import { toast } from 'react-toastify';
import { selectToastMessage } from './slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { BoxPat } from 'assets/images';

interface IProps {
  children: React.ReactNode;
  window?: () => Window;
}

export default function DefaultLayout(props: IProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { actions } = useDefaultLayoutSlice();
  const toastData = useSelector(selectToastMessage);
  const dispatch = useDispatch();
  const toastID = React.useRef<string | number | undefined>(undefined);
  const [prevCountValue, setPrevCountValue] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    httpClient.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);
  React.useEffect(() => {
    if (
      !toast.isActive(toastID.current as React.ReactText) &&
      prevCountValue !== toastData?.count
    ) {
      toastID.current = toast(toastData?.currentMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        type: toastData?.currentVariant,
        toastId: toastData?.count,
      });
    }
    setPrevCountValue(toastData?.count);
    return () => {
      setPrevCountValue(undefined);
      toast.clearWaitingQueue();
      dispatch(actions.hideAppMessage());
    };
  }, [toastData?.count, prevCountValue]);

  return (
    <div
      className="w-full h-full top-0 left-0 z-0 bg-auto bg-fixed bg-repeat"
      style={{
        backgroundImage: `url(${BoxPat})`,
        backgroundPosition: '0 0',
      }}
    >
      <div className="z-10 w-full">{props.children}</div>
    </div>
  );
}
