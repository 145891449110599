/* eslint-disable array-callback-return */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { subscriptionSaga } from './saga';
import { IAPIKeyReturn, IAPIKeyState, SubscriptionsState } from './types';
import { EInvoice, ISubscription } from 'app/Model/subscription';

export const initialState: SubscriptionsState = {
  subscriptions: [],
  isCancelLoading: false,
  invoices: [],
  apiKey: {},
  newApiKey: {},
  isKeyLoading: false,
  isSubLoading: false,
  subscriptionModalState: false,
  subscriptionPagination: {
    limit: 10,
    offset: 1,
  },
  apiError: '',
};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    getSusbscriptions(state, action: PayloadAction<string>) {
      state.isSubLoading = true;
    },
    setSubscriptionState(state, action: PayloadAction<ISubscription[]>) {
      state.subscriptions = action.payload;
    },
    cancelSubscription(state, action: PayloadAction<string>) {
      state.isCancelLoading = true;
    },
    setCancelLoading(state, action: PayloadAction<boolean>) {
      state.isCancelLoading = action.payload;
    },
    setCustomerPortal(state, action: PayloadAction<string>) {
      state.customerPortalUrl = action.payload;
    },
    getInvoices(state, action: PayloadAction<string>) {
      state.isSubLoading = true;
    },
    createCustomer(state, action: PayloadAction<string>) {
      state.isSubLoading = true;
    },
    setInvoicesState(state, action: PayloadAction<EInvoice[]>) {
      state.invoices = action.payload;
    },
    setIsSubscriptionLoading(state, action: PayloadAction<boolean>) {
      state.isSubLoading = action.payload;
    },
    clearCustomerPortal(state) {
      state.customerPortalUrl = '';
    },
    toggleSubscriptionModalState(state, action: PayloadAction<boolean>) {
      state.subscriptionModalState = action.payload;
    },
    setPageNo(state, action: PayloadAction<number>) {
      state.subscriptionPagination = {
        offset: action.payload,
        limit: state.subscriptionPagination?.limit,
      };
    },
    getApiKey(state, action: PayloadAction<string>) {
      state.isKeyLoading = true;
    },
    setApiKey(state, action: PayloadAction<IAPIKeyState>) {
      state.apiKey = action.payload;
    },
    setIsKeyLoading(state, action: PayloadAction<boolean>) {
      state.isKeyLoading = action.payload;
    },
    getNewApiKey(state, action: PayloadAction<string>) {
      state.isKeyLoading = true;
    },
    setNewApiKey(state, action: PayloadAction<IAPIKeyReturn>) {
      state.newApiKey = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.apiError = action.payload;
    },
  },
});

export const { actions: subscriptionActions } = slice;
export const useSubscriptionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: subscriptionSaga });
  return { actions: slice.actions };
};
export const subscriptionReducers = slice.reducer;

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
