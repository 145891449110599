import { IAuthOConfig } from './type';

export function getConfig(): IAuthOConfig {
  const audience = process.env.REACT_APP_AUDIENCE;
  return {
    domain: process.env.REACT_APP_DOMAIN!,
    clientId: process.env.REACT_APP_CLIENTID!,
    ...(audience ? { audience } : null),
  };
}
