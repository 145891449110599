import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import profileValidation from 'app/components/Form/Validation/profileValidation';
import { Button } from 'app/components/Button';
import { IProfileProps } from './type';
import { Eye, EyeClosed, IconoirProvider } from 'iconoir-react';

const ProfileController = (props: IProfileProps) => {
  const [passwordInputType, setPasswordInputType] = React.useState(true);

  const [copyLabel, setCopyLabel] = React.useState(false);
  const [confirmPasswordInputType, setConfirmPasswordInputType] =
    React.useState(true);

  const handlePasswordInputType = () => {
    setPasswordInputType(!passwordInputType);
  };
  const handleConfirmPasswordInputType = () => {
    setConfirmPasswordInputType(!confirmPasswordInputType);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopyLabel(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  });
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={profileValidation}
      validateOnChange={true}
      onSubmit={values => {
        props.onSubmit(values);
      }}
    >
      {({ isSubmitting, getFieldProps, handleChange, handleBlur, values }) => (
        <Form>
          <div className="w-full flex flex-col gap-y-4">
            <div className="w-full flex flex-col gap-y-4">
              <div className="font-bold">API Key</div>
              <div className="w-full flex flex-row items-center gap-4 overflow-hidden">
                <div className="w-3/5 flex flex-col items-start justify-center bg-white h-14 max-h-[80px] rounded-xl px-4">
                  <div className="w-full text-black truncate ">
                    {props.apiError !== ''
                      ? props.apiError
                      : props.isKeyLoading
                      ? 'Loading...'
                      : props.apiKey}
                  </div>
                </div>
                <div className="w-2/5 max-w-[100px]">
                  {props.apiError !== '' ? (
                    <></>
                  ) : props.apiKey !== '' ? (
                    <Button
                      type="unfilled"
                      className="w-full py-3"
                      onClick={e => {
                        e.preventDefault();
                        if (props.apiKey && props.apiKey !== '') {
                          navigator.clipboard
                            .writeText(props.apiKey)
                            .then(() => {
                              setCopyLabel(true);
                            });
                        }
                      }}
                    >
                      {copyLabel ? 'Copied' : 'Copy'}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-full flex flex-col gap-y-4">
                <div className="font-bold">Name</div>
                <input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="john@example.com"
                  className="indent-3 text-black rounded-xl w-full h-14 max-h-[80px]"
                />
                <ErrorMessage name="name">
                  {msg => <div className="text-red text-base">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 sm:gap-y-0 sm:flex-row justify-between">
              <div className="w-full sm:w-[48%] flex flex-col gap-y-4">
                <div className="font-bold">Given Name</div>
                <input
                  name="given_name"
                  value={values.given_name}
                  onChange={handleChange}
                  placeholder=""
                  className="indent-3 text-black rounded-xl w-full h-14 max-h-[80px]"
                />
                <ErrorMessage name="given_name">
                  {msg => <div className="text-red text-base">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="w-full sm:w-[48%] flex flex-col gap-y-4">
                <div className="font-bold">Family Name</div>
                <input
                  name="family_name"
                  value={values.family_name}
                  onChange={handleChange}
                  placeholder=""
                  className="indent-3 text-black rounded-xl w-full h-14 max-h-[80px]"
                />
                <ErrorMessage name="family_name">
                  {msg => <div className="text-red text-base">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="flex flex-col gap-y-4 sm:gap-y-0 sm:flex-row justify-between">
              <div className="w-full sm:w-[48%] flex flex-col gap-y-4">
                <div className="font-bold">Password</div>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-center relative">
                    <input
                      name="password"
                      onChange={handleChange}
                      type={passwordInputType ? 'password' : 'text'}
                      placeholder="Password"
                      className="indent-3 text-black rounded-xl w-full h-14 max-h-[80px]"
                    />
                    <div className="absolute right-2">
                      {passwordInputType ? (
                        <IconoirProvider
                          iconProps={{
                            color: '#707070',
                            strokeWidth: 1,
                            width: '2em',
                            height: '2em',
                          }}
                        >
                          <Eye onClick={handlePasswordInputType} />
                        </IconoirProvider>
                      ) : (
                        <IconoirProvider
                          iconProps={{
                            color: '#707070',
                            strokeWidth: 1,
                            width: '2em',
                            height: '2em',
                          }}
                        >
                          <EyeClosed onClick={handlePasswordInputType} />
                        </IconoirProvider>
                      )}
                    </div>
                  </div>
                  <ErrorMessage name="password">
                    {msg => (
                      <div className="text-[#d41b1b] text-sm text-center py-2">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
              <div className="w-full sm:w-[48%] flex flex-col gap-y-4">
                <div className="font-bold">Confirm Password</div>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-center relative">
                    <input
                      name="confirmPassword"
                      onChange={handleChange}
                      type={confirmPasswordInputType ? 'password' : 'text'}
                      placeholder="Password"
                      className="indent-3 text-black rounded-xl w-full h-14 max-h-[80px]"
                    />
                    <div className="absolute right-2">
                      {confirmPasswordInputType ? (
                        <IconoirProvider
                          iconProps={{
                            color: '#707070',
                            strokeWidth: 1,
                            width: '2em',
                            height: '2em',
                          }}
                        >
                          <Eye onClick={handleConfirmPasswordInputType} />
                        </IconoirProvider>
                      ) : (
                        <IconoirProvider
                          iconProps={{
                            color: '#707070',
                            strokeWidth: 1,
                            width: '2em',
                            height: '2em',
                          }}
                        >
                          <EyeClosed onClick={handleConfirmPasswordInputType} />
                        </IconoirProvider>
                      )}
                    </div>
                  </div>
                  <ErrorMessage name="confirmPassword">
                    {msg => (
                      <div className="text-[#d41b1b] text-sm text-center py-2">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-row items-center justify-center mt-10">
              <Button
                type="unfilled"
                subType={true}
                className={`${
                  props.isSubmittingForm ? 'px-20 py-6' : 'px-16 py-3'
                }`}
                loadingState={props.isSubmittingForm}
                disabled={props.isSubmittingForm}
              >
                Update
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileController;
