import * as yup from 'yup';

const profileValidationSchema = yup.object().shape({
  name: yup.string(),
  family_name: yup.string(),
  given_name: yup.string(),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export default profileValidationSchema;
