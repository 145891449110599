import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const HubIframeComponent = () => {
  const [src, setSrc] = useState<string | undefined>(undefined);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let token = localStorage.getItem('token');

    token = token?.replace(/['"]+/g, '') ?? null;

    console.log(`Storage Token: ${token}`);

    if (token) {
      setSrc(`https://hub.sov.ai/hub/login?identity=${token}`);
    } else {
      getAccessTokenSilently().then(networkToken => {
        if (networkToken) {
          localStorage.setItem('token', networkToken);
          setSrc(`https://hub.sov.ai/hub/login?identity=${networkToken}`);
        }
      });
    }
  }, [getAccessTokenSilently]);

  return src ? (
    <iframe
      src={src}
      title="SovAI Dash"
      style={{ height: '100vh' }}
      height="100%"
      width="100%"
    ></iframe>
  ) : (
    <div>Loading...</div>
  );
};

export default HubIframeComponent;
