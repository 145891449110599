// DatasetModal.tsx
import React, { useEffect, useState } from 'react';
import frontMatter, { FrontMatterResult } from 'front-matter';

// Interface for Front Matter Attributes
interface FrontMatterAttributes {
  description?: string;
  // Removed icon as per the latest requirements
  // Additional fields are extracted from the markdown body
}

// Interface for Dataset Modal Props
interface DatasetModalProps {
  dataset: {
    name: string;
    link: string;
  } | null;
  onClose: () => void;
}

// Interface for Data Dictionary Items (Removed as per requirements)
interface DataDictionaryItem {
  name: string;
  description: string;
  type: string;
  example: string;
}

// CodeBlock Component with Copy Functionality and Manual Syntax Highlighting
interface CodeBlockProps {
  code: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState<string>('');

  const handleCopy = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000);
      })
      .catch(() => {
        setCopySuccess('Failed to copy!');
        setTimeout(() => setCopySuccess(''), 2000);
      });
  };

  // Function to manually highlight code based on specified rules
  const renderHighlightedCode = () => {
    // Split code into lines
    const lines = code.split('\n');

    return lines.map((line, index) => {
      // Split each line into tokens based on specific keywords and patterns
      // The regex captures the tokens we want to style
      const tokens = line.split(/(\bfrom\b|\bimport\b|\bsovai\b|\bsov\b|data\(|\)|"[^"]+")/g);

      return (
        <div key={index} className="whitespace-pre-wrap">
          {tokens.map((token, idx) => {
            if (token === 'from' || token === 'import') {
              return <span key={idx} className="text-pink-500">{token}</span>;
            }
            if (token === 'sovai' || token === 'sov') {
              return <span key={idx} className="text-purple-500">{token}</span>;
            }
            if (token === 'data(' || token === ')') {
              return <span key={idx} className="text-white">{token}</span>;
            }
            if (/^".*"$/.test(token)) { // String literals
              return <span key={idx} className="text-green-400">{token}</span>;
            }
            return <span key={idx} className="text-white">{token}</span>;
          })}
        </div>
      );
    });
  };

  return (
    <div className="relative">
      <button
        onClick={handleCopy}
        className="absolute top-2 right-2 bg-gray-700 text-white px-2 py-1 rounded text-sm hover:bg-gray-600 focus:outline-none"
        aria-label="Copy code"
      >
        {copySuccess ? copySuccess : 'Copy'}
      </button>
      <pre className="bg-[#2d2d2d] p-4 rounded overflow-auto">
        <code className="font-mono text-sm">
          {renderHighlightedCode()}
        </code>
      </pre>
    </div>
  );
};

const DatasetModal: React.FC<DatasetModalProps> = ({ dataset, onClose }) => {
  // State variables for front matter and extracted sections
  const [description, setDescription] = useState<string>('');
  const [tutorialLink, setTutorialLink] = useState<string>('');
  const [categories, setCategories] = useState<{ [key: string]: string }>({});
  const [dataAccessCode, setDataAccessCode] = useState<string | null>(null); // Updated to string | null
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // Helper function to extract a section based on a header (Markdown)
  const extractMarkdownSection = (markdown: string, header: string): string => {
    const regex = new RegExp(`##\\s+${header}\\s*([\\s\\S]*?)(?=##\\s+|$)`, 'i');
    const match = markdown.match(regex);
    return match ? match[1].trim() : '';
  };

  // Helper function to extract the Tutorials link
  const extractTutorialLink = (markdown: string): string => {
    const regex = /`Tutorials` are the best documentation — \[<mark[^>]*>`[^`]+`<\/mark>\]\(([^)]+)\)/i;
    const match = markdown.match(regex);
    return match ? match[1] : '';
  };

  // Helper function to parse a Markdown table into key-value pairs
  const parseMarkdownTable = (tableMarkdown: string): { [key: string]: string } => {
    const lines = tableMarkdown.split('\n').filter(line => line.trim() !== '');
    if (lines.length < 2) return {};

    const headers = lines[0].split('|').map(header => header.trim().toLowerCase());
    const categoryIndex = headers.indexOf('category');
    const detailsIndex = headers.indexOf('details');

    if (categoryIndex === -1 || detailsIndex === -1) return {};

    const categories: { [key: string]: string } = {};

    // Iterate over the table rows starting from the third line
    for (let i = 2; i < lines.length; i++) {
      const cells = lines[i].split('|').map(cell => cell.trim());
      if (cells.length > Math.max(categoryIndex, detailsIndex)) {
        let category = cells[categoryIndex].replace(/<\/?strong>/g, '').replace(/\*+/g, '').trim();
        let details = cells[detailsIndex].replace(/<\/?strong>/g, '').replace(/\*+/g, '').trim();

        categories[category] = details;
      }
    }

    return categories;
  };

  // Helper function to parse an HTML table into key-value pairs
  const parseHTMLTable = (tableHTML: string): { [key: string]: string } => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(tableHTML, 'text/html');
    const table = doc.querySelector('table');
    if (!table) return {};

    const headers = Array.from(table.querySelectorAll('th')).map(th => th.textContent?.trim().toLowerCase() || '');
    const categoryIndex = headers.indexOf('category');
    const detailsIndex = headers.indexOf('details');

    if (categoryIndex === -1 || detailsIndex === -1) return {};

    const categories: { [key: string]: string } = {};

    const rows = table.querySelectorAll('tbody tr');
    rows.forEach(row => {
      const cells = Array.from(row.querySelectorAll('td')).map(td => td.textContent?.trim() || '');
      if (cells.length > Math.max(categoryIndex, detailsIndex)) {
        let category = cells[categoryIndex].replace(/<\/?strong>/g, '').replace(/\*+/g, '').trim();
        let details = cells[detailsIndex].replace(/<\/?strong>/g, '').replace(/\*+/g, '').trim();

        categories[category] = details;
      }
    });

    return categories;
  };

  // Helper function to extract the first Python code block from the entire markdown
  const extractFirstPythonCodeBlock = (markdown: string): string | null => {
    const regex = /```python\s*([\s\S]*?)```/i;
    const match = markdown.match(regex);
    return match ? match[1].trim() : null;
  };

  // Helper function to parse content into the first code block
  const parseDataAccessContent = (markdown: string, codeBlock: string | null): JSX.Element[] => {
    const blocks: JSX.Element[] = [];

    if (codeBlock) {
      // Remove the first Python code block from the markdown
      let contentWithoutCode = markdown.replace(/```python\s*([\s\S]*?)```/i, '').trim();

      // Remove any markdown headings (lines starting with #)
      contentWithoutCode = contentWithoutCode.split('\n').filter(line => !/^#+\s/.test(line)).join('\n').trim();

      // Since we're removing the description, we skip rendering any text and directly render the code block
      blocks.push(
        <CodeBlock key="data-access-code" code={codeBlock} />
      );
    }

    return blocks;
  };

  useEffect(() => {
    if (!dataset) return;

    const fetchMarkdown = async () => {
      setLoading(true);
      setError('');
      setDescription('');
      setTutorialLink('');
      setCategories({});
      setDataAccessCode(null); // Initialize as null

      try {
        // Derive the Markdown file path from the dataset link
        const urlPath = new URL(dataset.link).pathname;
        const markdownPath = `${urlPath}.md`.replace('/realtime-datasets/', 'realtime-datasets/'); // Adjust if necessary

        // Construct the raw GitHub URL
        const rawUrl = `https://raw.githubusercontent.com/sovai-research/sovai-documentation/main/${markdownPath}`;

        const response = await fetch(rawUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch dataset information.');
        }

        const markdown = await response.text();

        // Parse the front matter with proper typing
        const content: FrontMatterResult<FrontMatterAttributes> = frontMatter<FrontMatterAttributes>(markdown);

        // Set description from front matter
        setDescription(content.attributes.description || 'No description available for this dataset.');

        // Extract Tutorials link
        const tutorial = extractTutorialLink(markdown);
        setTutorialLink(tutorial);

        // Extract Categories from HTML or Markdown table
        // First, attempt to find an HTML table with Category and Details headers
        const htmlTableRegex = /<table[^>]*>([\s\S]*?)<\/table>/i;
        const htmlTableMatch = markdown.match(htmlTableRegex);
        let categoriesParsed: { [key: string]: string } = {};

        if (htmlTableMatch) {
          categoriesParsed = parseHTMLTable(htmlTableMatch[0]);
          if (Object.keys(categoriesParsed).length > 0) {
            setCategories(categoriesParsed);
          }
        }

        // If no HTML table found or categories not parsed, attempt to find a Markdown table
        if (Object.keys(categoriesParsed).length === 0) {
          const categoriesMarkdown = extractMarkdownSection(markdown, 'Category');
          if (categoriesMarkdown) {
            categoriesParsed = parseMarkdownTable(categoriesMarkdown);
            setCategories(categoriesParsed);
          }
        }

        // Extract Data Access
        const dataAccessSection = extractMarkdownSection(markdown, 'Data Access');
        // Only set the code block, no description
        const firstPythonCodeBlock = extractFirstPythonCodeBlock(markdown);
        setDataAccessCode(firstPythonCodeBlock); // Now accepts string | null
      } catch (err: any) {
        console.error(err);
        setError('Unable to load dataset information.');
      } finally {
        setLoading(false);
      }
    };

    fetchMarkdown();
  }, [dataset]);

  if (!dataset) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      onClick={onClose}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="bg-[#1a1a1a] rounded-lg p-6 max-w-4xl w-full relative overflow-y-auto max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
        tabIndex={-1}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
          aria-label="Close modal"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Modal Header */}
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-white">{dataset.name}</h2>
        </div>

        {/* Modal Content */}
        {loading && (
          <div className="flex justify-center my-4">
            <div className="w-8 h-8 border-4 border-[#775dda] border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}

        {error && <p className="text-red-500">{error}</p>}

        {!loading && !error && (
          <>
            {/* Description */}
            <div className="mb-4">
              <h3 className="text-xl font-semibold text-white mb-2">Description</h3>
              <p className="text-gray-300">{description}</p>
            </div>

            {/* Tutorials Link */}
            {tutorialLink && (
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-white mb-2">Tutorial</h3>
                <a
                  href={tutorialLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#775dda] underline hover:text-[#8b6dea] transition-colors"
                >
                  View Tutorial
                </a>
              </div>
            )}

            {/* Categories and Details */}
            {Object.keys(categories).length > 0 && (
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-white mb-2">Categories</h3>
                <ul className="list-disc list-inside text-gray-300">
                  {Object.entries(categories).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Data Access Instructions */}
            {dataAccessCode && (
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-white mb-2">Data Access</h3>
                {/* Render only the first code block */}
                <CodeBlock code={dataAccessCode} />
              </div>
            )}

            {/* Removed Data Dictionary Section */}

            {/* Documentation Link */}
            <a
              href={dataset.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-6 px-6 py-2 bg-[#775dda] text-white rounded-lg hover:bg-[#8b6dea] transition-colors"
            >
              View Documentation
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default DatasetModal;
