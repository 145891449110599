import React from 'react';
import { Banner } from 'assets/images';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className="w-full h-full top-0 left-0 z-0 bg-auto lg:bg-cover bg-fixed bg-repeat"
      style={{
        backgroundImage: `url(${Banner})`,
        backgroundPosition: '0 -30px',
      }}
    >
      <div className="z-10 w-full">{children}</div>
    </div>
  );
};

export default MainLayout;
