import DefaultLayout from '../pages/DefaultLayout';
import { IProps } from './types/AuthenticatedTypes';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Lottie from 'react-lottie';
import loading from 'assets/lotties/loading.json';

export const AuthenticationGuard = ({ component }: IProps) => {
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="flex flex-col items-center justify-center h-screen w-full relative">
        <div className="max-w-[250px] w-1/3">
          <Lottie options={lottieDefaultOptions} width="100%" />
        </div>
      </div>
    ),
    returnTo: window.location.pathname,
  });

  return (
    <DefaultLayout>
      <Component />
    </DefaultLayout>
  );
};
