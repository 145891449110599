import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import lottie404 from 'assets/lotties/404.json';
import { Button } from 'app/components/Button';
import Lottie from 'react-lottie';
import verify from 'assets/lotties/verify.json';
import { useLocation } from 'react-router-dom';
import MainLayout from 'app/pages/MainLayout';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const { state } = useParams();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const reason = urlParams.get('reason') == 'email_not_verified';
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: verify,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };
  const lottieDefaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: lottie404,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };
  return (
    <MainLayout>
      {reason ? (
        <div className="h-screen w-full flex flex-col justify-center items-center ">
          <div className="flex flex-col justify-center items-center p-8">
            <div className="flex flex-col justify-center items-center w-3/5 max-w-[250px]">
              <Lottie options={lottieDefaultOptions} width="100%" />
            </div>
            <div className="flex flex-col items-center justify-center mb-8">
              <div className="text-xl lg:text-3xl font-bold text-white mt-4 text-center">
                Please Verify Your Email
              </div>
              <div className="text-xs lg:text-lg text-white mt-4 text-center">
                Check your email for a verification link!
              </div>
            </div>
            <Button
              type="custom"
              className="border-2 border-[#4a3d8a] bg-[#383064] hover:bg-[#11161f88] text-[#f8f8f8] py-2 lg:py-3 px-6 lg:px-10 rounded-md text-sm lg:text-base"
              onClick={() => navigate('/')}
            >
              Back to Home
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen ">
          <div className="w-1/3 flex flex-col">
            <Lottie options={lottieDefaultOptions2} width="100%" />
          </div>
          <div>
            <Button
              type="custom"
              className="border-2 border-[#4a3d8a] bg-[#383064] hover:bg-[#11161f88] text-[#f8f8f8] py-4 px-10 rounded-md"
              onClick={() => navigate('/home')}
            >
              Back to Home
            </Button>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
