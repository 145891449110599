import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { IProfileUser } from './types';

const selectSlice = (state: RootState) => state?.userProfile || initialState;

export const selectUserProfile: (state: RootState) => IProfileUser =
  createSelector([selectSlice], state => state);

export const selectUser = createSelector(
  [selectUserProfile],
  state => state.userProfile,
);

export const selectGettingProfile = createSelector(
  [selectUserProfile],
  state => state.isProfileStateLoading,
);

export const selectApiKey = createSelector(
  [selectUserProfile],
  state => state.apiKey,
);

export const selectGettingApiKey = createSelector(
  [selectUserProfile],
  state => state.isKeyLoading,
);
