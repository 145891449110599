import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

interface Props {
  iteration: number;
}

const BodySkeleton = styled(Skeleton)`
  border-radius: 0px 30px 30px 30px;
`;

const HeaderSkeleton = styled(Skeleton)`
  border-radius: 30px 30px 0px 0px;
`;

export default function Variants(props: Props) {
  const [loaders, setLoaders] = React.useState<number[]>([]);

  React.useEffect(() => {
    let start = 0;
    const loadersCount: number[] = [];
    while (start < props.iteration) {
      loadersCount.push(start);
      start++;
    }

    setLoaders(loadersCount);
  }, [props.iteration]);

  return (
    <div className="flex flex-row w-full h-full justify-center items-center gap-2">
      {loaders.map(value => (
        <div className="w-full" key={value}>
          <div className="flex flex-row justify-start">
            <HeaderSkeleton
              sx={{
                width: [200, 310],
              }}
              variant="rectangular"
              height={50}
            />
          </div>
          <BodySkeleton
            sx={{
              height: [400, 290],
              width: [270, 400],
            }}
            variant="rectangular"
          />
        </div>
      ))}
    </div>
  );
}
