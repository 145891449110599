import React, { useEffect } from 'react';
import { Button } from 'app/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { popUpModal } from 'app/components/OpenModal';
import { PopUpModalProps } from '../types/PopUpModalTypes';

const banner = require('assets/images/banner.jpg');

interface IAPI {
  apiKey?: string;
  newApiKey?: string;
  isKeyLoading: boolean;
  requestNewKey: () => void;
  apiError?: string;
}

export default function RenderApi(props: IAPI) {
  const [modalState, setModalState] = React.useState(false);
  const [copyLabel, setCopyLabel] = React.useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setModalState(false);
  };

  const handleClickOpen = () => {
    setModalState(true);
  };

  const handleApiRequest = () => {
    props.requestNewKey();
    setModalState(false);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopyLabel(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [copyLabel]);

  const apiModalContent: PopUpModalProps = {
    open: modalState,
    content: {
      title: 'Request New Key',
      description: 'Are you sure you want to request a new API Key?',
    },
    handleClose: handleClose,
    handleAction: handleApiRequest,
    actionName: 'Yes',
  };

  return (
    <div>
      {popUpModal(apiModalContent)}
      <div className="max-w-[420px] p-6 w-full rounded-3xl bg-[#0f141f] border-1 border-[#1d2838] p-4 text-[#212121] rounded-5 relative flex flex-col items-center justify-center">
        {props.apiError == '' ? (
          <>
            <div className="w-full p-2 mb-2">
              <div className="text-2xl font-bold text-[#f8f8f8]">
                Active API Key
              </div>
              <div className="h-10 w-full cursor-text rounded-xl border border-[#364255] flex flex-col justify-center items-start text-[#f8f8f8] font-normal text-base px-2 mt-4 whitespace-nowrap ">
                <div className="w-full text-base truncate">
                  {props.apiError !== ''
                    ? props.apiError
                    : props.isKeyLoading
                    ? 'Loading...'
                    : props.newApiKey && props.newApiKey.length != 0
                    ? props.newApiKey
                    : props.apiKey}
                </div>
              </div>
            </div>

            <div className="px-2 w-full flex flex-row items-center justify-center gap-2">
              <Button
                className="w-2/5 py-3 text-[#f8f8f8]"
                type="unfilled"
                onClick={() => {
                  props.isKeyLoading
                    ? navigator.clipboard.writeText('').then(() => {
                        setCopyLabel(true);
                      })
                    : props.newApiKey
                    ? navigator.clipboard
                        .writeText(props.newApiKey)
                        .then(() => {
                          setCopyLabel(true);
                        })
                    : props.apiKey
                    ? navigator.clipboard.writeText(props.apiKey).then(() => {
                        setCopyLabel(true);
                      })
                    : navigator.clipboard.writeText('').then(() => {
                        setCopyLabel(true);
                      });
                }}
              >
                {copyLabel ? 'Copied' : 'Copy'}
              </Button>
              <Button
                className="w-3/5 py-3 text-[#f8f8f8]"
                type="filled"
                onClick={handleClickOpen}
              >
                Request New
              </Button>
            </div>
          </>
        ) : (
          <div className="text-base text-[#f8f8f8] w-full p-2 mt-4">
            {props.apiError}
          </div>
        )}
      </div>
    </div>
  );
}
