import { call, put, takeLatest } from 'redux-saga/effects';

import makeCall from 'app/API/makeCalls';
import routes from 'app/API/api.routes';
import { userProfileActions as actions } from '.';
import { PayloadAction } from '@reduxjs/toolkit';
import { defaultLayoutActions } from 'app/pages/DefaultLayout/slice';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from 'app/pages/DefaultLayout/constants';
import { TypeOptions } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';
import { IProfileFormValues, IProfileUpdate } from '../type';
import { IAPIKey } from './types';

function* getApiKeySaga(action: PayloadAction<string>) {
  try {
    const response: IAPIKey = yield call(makeCall, {
      method: 'GET',
      route: routes.profile.apiKey,
      query: {
        user_id: action.payload,
      },
    });
    if (
      typeof response === 'object' &&
      !Array.isArray(response) &&
      response !== null &&
      response.token.value !== ''
    ) {
      yield put(actions.setApiKey(response.token));
      yield put(actions.setIsKeyLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsKeyLoading(false));
    yield put(
      actions.setErrorMessage('You need a paid account for this service'),
    );
    console.log('Err', error);
  }
}

function* updateUserSaga(action: PayloadAction<IProfileUpdate>) {
  try {
    const { id, ...rest } = action.payload;
    const body: IProfileFormValues = {};
    if (rest.name !== undefined) {
      body.name = rest.name;
      body.email = rest.name;
    }
    if (rest.password !== undefined && rest.password !== '') {
      body.password = rest.password;
    }
    if (rest.family_name !== undefined) {
      body.family_name = rest.family_name;
    }
    if (rest.given_name !== undefined) {
      body.given_name = rest.given_name;
    }
    const response = yield call(makeCall, {
      method: 'PUT',
      route: `${routes.user.create}?user_id=${action.payload.id}`,
      body: { ...body },
    });
    if (response) {
      yield put(actions.setIsProfileLoading(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        }),
      );
      window.location.reload();
    }
  } catch (error: any) {
    yield put(actions.setIsProfileLoading(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: 'Something went wrong!',
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      }),
    );
  }
}

export function* userProfileSaga() {
  yield takeLatest(actions.updateUserProfileState.type, updateUserSaga);
  yield takeLatest(actions.getApiKey.type, getApiKeySaga);
}
