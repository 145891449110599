import { call, put, takeLatest } from 'redux-saga/effects';

import makeCall from 'app/API/makeCalls';
import routes from 'app/API/api.routes';
import { IAPIKey, IAPIKeyReturn, ISetSubscription } from './types';
import { subscriptionActions as actions } from '.';
import { EInvoice, ISubscriptionBase } from 'app/Model/subscription';
import { PayloadAction } from '@reduxjs/toolkit';
import { defaultLayoutActions } from 'app/pages/DefaultLayout/slice';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from 'app/pages/DefaultLayout/constants';
import { TypeOptions } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';

function* createCustomerPortalSaga(action: PayloadAction<string>) {
  try {
    const response = yield call(makeCall, {
      method: 'POST',
      route: routes.subscribe.customer_portal,
      body: {
        user_id: action.payload,
      },
    });
    if (response) {
      yield put(actions.setCustomerPortal(response));
      yield put(actions.setIsSubscriptionLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsSubscriptionLoading(false));
  }
}

function* getSubscriptionsSaga(action: PayloadAction<string>) {
  try {
    const response: ISetSubscription = yield call(makeCall, {
      method: 'GET',
      route: routes.subscribe.list,
    });
    if (
      response?.result &&
      Array.isArray(response?.result) &&
      response?.result?.length
    ) {
      yield put(actions.setSubscriptionState(response?.result));
    }
    yield put(actions.setIsSubscriptionLoading(false));
  } catch (error: any) {
    yield put(actions.setIsSubscriptionLoading(false));
    console.log('Err', error);
  }
}

function* getIInvoicesSaga(action: PayloadAction<string>) {
  try {
    const response: EInvoice[] = yield call(makeCall, {
      method: 'GET',
      route: routes.subscribe.get_invoice,
    });
    if (response && Array.isArray(response) && response?.length) {
      yield put(actions.setInvoicesState(response));
    }
    yield put(actions.setIsSubscriptionLoading(false));
  } catch (error: any) {
    yield put(actions.setIsSubscriptionLoading(false));
    console.log('Err', error);
  }
}

function* cancelSubscriptionSaga(action: PayloadAction<string>) {
  try {
    const response: ISubscriptionBase = yield call(makeCall, {
      method: 'POST',
      route: `${routes.subscribe.list}/${action.payload}`,
    });
    if (response) {
      yield put(actions.toggleSubscriptionModalState(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage:
            'Your subscription will be successfully canceled by the end of the billing cycle',
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        }),
      );
      yield put(actions.setCancelLoading(false));
      window.location.reload();
    }
  } catch (error: any) {
    yield put(actions.toggleSubscriptionModalState(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: 'Something went wrong!',
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      }),
    );
    yield put(actions.setCancelLoading(false));
  }
}

function* getApiKeySaga(action: PayloadAction<string>) {
  try {
    const response: IAPIKey = yield call(makeCall, {
      method: 'GET',
      route: routes.profile.apiKey,
    });
    if (
      typeof response === 'object' &&
      !Array.isArray(response) &&
      response !== null
    ) {
      yield put(actions.setApiKey(response.token!));
    }
    yield put(actions.setIsKeyLoading(false));
  } catch (error: any) {
    yield put(actions.setIsKeyLoading(false));
    yield put(
      actions.setErrorMessage('You need a paid account for this service'),
    );
    console.log('Err', error);
  }
}

function* getNewApiKeySaga(action: PayloadAction<string>) {
  try {
    const response: IAPIKeyReturn = yield call(makeCall, {
      method: 'POST',
      route: routes.profile.apiKey,
    });
    if (
      typeof response === 'object' &&
      !Array.isArray(response) &&
      response !== null &&
      response.token !== ''
    ) {
      yield put(actions.setNewApiKey(response));
      yield put(actions.setIsKeyLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsKeyLoading(false));
    console.log('Err', error);
  }
}

export function* subscriptionSaga() {
  yield takeLatest(actions.getSusbscriptions.type, getSubscriptionsSaga);
  yield takeLatest(actions.getInvoices.type, getIInvoicesSaga);
  yield takeLatest(actions.cancelSubscription.type, cancelSubscriptionSaga);
  yield takeLatest(actions.createCustomer.type, createCustomerPortalSaga);
  yield takeLatest(actions.getApiKey.type, getApiKeySaga);
  yield takeLatest(actions.getNewApiKey.type, getNewApiKeySaga);
}
