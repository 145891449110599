import React, { useState } from 'react';
import { PaymentIntent, loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const StripePage = () => {
  const location = useLocation();
  const { user } = useAuth0();
  const [clientSecret] = useState(location.state.clientSecret);
  const [subscriptionId] = useState(location.state.subscriptionId);
  const [name, setName] = useState(user?.name);
  const [messages, _setMessages] = useState('');
  const [paymentIntent, setPaymentIntent] = useState<
    PaymentIntent | undefined
  >();
  const navigate = useNavigate();
  const setMessage = message => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return <></>;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement!,
          billing_details: {
            name: name,
          },
        },
      },
    );

    if (error) {
      setMessage(error.message);
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === 'succeeded') {
    navigate('/stripe');
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Full name
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </label>

      <CardElement />

      <button>Subscribe</button>

      <div>{messages}</div>
    </form>
  );
};

export default StripePage;
