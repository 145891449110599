import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const LogoutPage = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    localStorage.removeItem('token');
    setTimeout(async () => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });

      window.location.href = '/';
    }, 2000);
  }, []);

  return (
    <div>
      <iframe
        src={'https://app.sov.ai/identity/logout'}
        className="hidden"
      ></iframe>
      <iframe
        src={'https://hub.sov.ai/identity/logout'}
        className="hidden"
      ></iframe>
      <div>Logging out...</div>
    </div>
  );
};

export default LogoutPage;
