import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscriptionSlice } from './slice';
import { useAuth0 } from '@auth0/auth0-react';
import { selectSubscriptions } from './slice/selector';
import Loading from '../HomePage/loading';
import Lottie from 'lottie-react';
import noresultreturned from 'assets/lotties/noresultreturned.json';
import RenderSubscription from 'app/components/SubscriptionCard';
import InvoiceTable from 'app/components/InvoiceTable';
import styled from 'styled-components';
import RenderApi from 'app/components/ApiCard';
import { HomeLayout } from 'app/components/HomeLayout';

const SubscriptionPage = () => {
  const [selectedTab, setSelectedTab] = useState('SUBSCRIPTIONS');

  const renderContent = () => {
    switch (selectedTab) {
      case 'SUBSCRIPTIONS':
        return (
          <div className="w-full">
            {!subscriptions ? (
              <Loading iteration={12} />
            ) : (
              <div className="w-full">
                {subscriptions && subscriptions?.length === 0 ? (
                  <div className="w-full flex flex-row flex-wrap gap-x-[2%] gap-y-4">
                    <div className="max-w-[320px] w-full lg:w-[45%] xl:w-[30%]">
                      <Lottie
                        animationData={lottieDefaultOptions.animationData}
                        loop={lottieDefaultOptions.loop}
                        autoplay={lottieDefaultOptions.autoplay}
                        // renderSettings={lottieDefaultOptions.renderSettings}
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-row flex-wrap gap-x-[2%] gap-y-4">
                    {subscriptions?.map(subscription => (
                      <div className="max-w-[350px] w-full lg:w-[45%] xl:w-[30%]">
                        <RenderSubscription
                          subscription={subscription}
                          isSubLoading={isSubLoading}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 'INVOICES':
        return <InvoiceTable rows={invoices} />;
      case 'API KEYS':
        return (
          <RenderApi
            apiKey={apiKey?.value}
            newApiKey={newApiKey?.token}
            isKeyLoading={isKeyLoading}
            requestNewKey={() => {
              dispatch(actions.getNewApiKey(user?.sub!));
            }}
            apiError={apiError}
          />
        );
      default:
        return null;
    }
  };
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const {
    invoices,
    apiKey,
    newApiKey,
    isKeyLoading,
    isSubLoading,
    subscriptions,
    customerPortalUrl,
    subscriptionModalState,
    apiError,
  } = useSelector(selectSubscriptions);
  const { actions } = useSubscriptionSlice();
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noresultreturned,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };
  if (customerPortalUrl) {
    window.open(customerPortalUrl);
    dispatch(actions.clearCustomerPortal());
  }
  useEffect(() => {
    dispatch(actions.getSusbscriptions(user?.sub!));
  }, []);
  return (
    <HomeLayout>
      <div className="flex flex-col items-start justify-start text-white px-8 py-4">
        <div className="pb-2 flex flex-row justify-start items-center text-center text-2xl md:text-4xl font-bold bg-gradient-to-r-custom text-gradient leading-extra-loose mb-8">
          Manage your subscription
        </div>
        <div className="w-full flex flex-row flex-wrap gap-x-4 text-lg">
          <div
            className={`mb-4 sm:mb-0 text-center text-base cursor-pointer pb-2 sm:px-4 ${
              selectedTab === 'SUBSCRIPTIONS'
                ? 'border-b-2 border-[#775dda] text-[#775dda]'
                : ''
            }`}
            onClick={() => {
              dispatch(actions.getSusbscriptions(user?.sub!));
              setSelectedTab('SUBSCRIPTIONS');
            }}
          >
            SUBSCRIPTIONS
          </div>
          <div
            className={`mb-4 sm:mb-0 text-center text-base cursor-pointer pb-2 px-4 ${
              selectedTab === 'INVOICES'
                ? 'border-b-2 border-[#775dda] text-[#775dda]'
                : ''
            }`}
            onClick={() => {
              dispatch(actions.getInvoices(user?.sub!));
              setSelectedTab('INVOICES');
            }}
          >
            INVOICES
          </div>
          <div
            className={`mb-4 sm:mb-0 text-center text-base cursor-pointer pb-2 px-4 ${
              selectedTab === 'API KEYS'
                ? 'border-b-2 border-[#775dda] text-[#775dda]'
                : ''
            }`}
            onClick={() => {
              dispatch(actions.getApiKey(user?.sub!));
              setSelectedTab('API KEYS');
            }}
          >
            API KEYS
          </div>
        </div>
        <div className="w-full mt-4">{renderContent()}</div>
      </div>
    </HomeLayout>
  );
};

export default SubscriptionPage;
