import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileController from 'app/components/FormikWrapper/ProfileForm';
import { IProfileFormValues } from './type';
import { useUserProfileSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from './slice/selector';
import { HomeLayout } from 'app/components/HomeLayout';

export default function ProfilePage() {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { actions } = useUserProfileSlice();
  const { userProfile, isProfileStateLoading, apiKey, isKeyLoading, apiError } =
    useSelector(selectUserProfile);
  const profileFormInitialValues: IProfileFormValues = {
    password: '',
    confirmPassword: '',
    family_name: user?.family_name,
    given_name: user?.given_name,
    name: user?.name,
  };
  useEffect(() => {
    dispatch(actions.getApiKey(user?.sub!));
  }, []);
  return (
    <HomeLayout>
      <div className="flex flex-col items-start justify-start text-white px-8 py-4">
        <div className="flex flex-row justify-center items-center text-center text-2xl md:text-4xl font-bold bg-gradient-to-r-custom text-gradient leading-extra-loose pb-2">
          Manage your Profile
        </div>
        <div className="w-full flex flex-col items-center justify-center mt-2">
          <div className="rounded-full w-36 overflow-hidden my-6">
            <img src={user?.picture} className="w-full h-full" alt="Profile" />
          </div>
          <div className="w-full xl:w-3/5 max-w-[1000px] sm:px-8">
            <ProfileController
              initialValues={profileFormInitialValues}
              apiKey={apiKey?.value}
              isKeyLoading={isKeyLoading}
              isSubmittingForm={isProfileStateLoading}
              onSubmit={(values: IProfileFormValues) => {
                const updateValues = { ...values, id: user?.sub! };
                dispatch(actions.updateUserProfileState(updateValues));
              }}
              apiError={apiError}
            />
          </div>
        </div>
      </div>
    </HomeLayout>
  );
}
