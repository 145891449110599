import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EInvoice } from 'app/Model/subscription';
import { Link } from 'react-router-dom';

interface IProps {
  rows?: EInvoice[];
}

export default function InvoiceTable({ rows }: IProps) {
  return (
    <div className="min-w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-[#11161faa]">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Invoice URL
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
              Amount Paid
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
              Period End
            </th>
          </tr>
        </thead>
        <tbody className="bg-[#383064aa] divide-y divide-gray-600">
          {rows?.map((row, idx) => (
            <tr key={uuidv4()} className="hover:bg-[#43368baa]">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                <Link
                  to={row.hosted_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-200 capitalize"
                >
                  invoice #{idx + 1}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-200">
                ${row.amount_paid / 100}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-200 capitalize">
                {row.status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-200">
                {new Date(row.period_end).toDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
