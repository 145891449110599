import React from 'react';
import SideBar from './Layout/SideBar';
import AppBar from './Layout/AppBar';

export const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="flex flex-row h-screen">
        <div className="h-full">
          <SideBar />
        </div>
        <div className="w-full lg:overflow-y-auto">
          <AppBar />
          {children}
        </div>
      </div>
    </>
  );
};
