import React from 'react';
import Lottie from 'lottie-react';
import loading from 'assets/lotties/loading.json';

type ButtonProps = {
  children: React.ReactNode;
  size?: string;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  loadingState?: boolean;
  subType?: boolean;
  disabled?: boolean;
};
export const Button = ({
  children,
  size = 'default',
  type = 'default',
  loadingState = false,
  disabled = false,
  subType = true,
  ...props
}: ButtonProps) => {
  return type === 'gradient' ? (
    <button
      onClick={props.onClick}
      className={`bg-gradient-to-br text-sm lg:text-lg px-6 lg:px-8 ${
        size === 'small'
          ? 'py-2 lg:py-2'
          : size === 'unset'
          ? ''
          : 'py-3 lg:py-4'
      } text-white rounded-[2rem] border border-blue-500 border-0 font-medium ${
        props.className
      } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  ) : type === 'filled' ? (
    <button
      onClick={props.onClick}
      className={`border border-[#775dda] bg-[#383064] rounded-md hover:bg-transparent hover:border-[#4a3d8a] ${props.className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  ) : type === 'unfilled' ? (
    loadingState ? (
      <div className="relative flex flex-row items-center justify-center">
        <button
          type={subType ? 'submit' : 'button'}
          onClick={props.onClick}
          className={`border bg-[#775dda] border-[#775dda] hover:bg-[#383064] rounded-md hover:border-[#4a3d8a] ${props.className} ${
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={disabled}
        >
          <div className="flex flex-row items-center justify-center justify-between">
            <div className="max-w-14 absolute left-0 ml-14">
              <Lottie
                animationData={loading}
                loop={true}
                autoplay={true}
                width="100%"
              />
            </div>
          </div>
        </button>
      </div>
    ) : (
      <button
        onClick={props.onClick}
        className={`border border-[#775dda] hover:bg-[#383064] rounded-md hover:border-[#4a3d8a] ${props.className} ${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={disabled}
      >
        {children}
      </button>
    )
  ) : type === 'custom' ? (
    <button
      onClick={props.onClick}
      className={`${props.className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  ) : (
    <button
      onClick={props.onClick}
      className={`border border-[#775dda] text-sm lg:text-lg px-6 lg:px-8 ${
        size === 'small'
          ? 'py-2 lg:py-2'
          : size === 'unset'
          ? ''
          : 'py-3 lg:py-4'
      } border-solid border-1 rounded-[2rem] text-white font-medium ${
        props.className
      } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

