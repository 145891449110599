/* eslint-disable array-callback-return */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userProfileSaga } from './saga';
import { IAPIKeyState, IProfileUser } from './types';
import { IProfileUpdate } from '../type';

export const initialState: IProfileUser = {
  userProfile: {},
  apiKey: {},
  isKeyLoading: false,
  isProfileStateLoading: false,
  apiError: '',
};

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    updateUserProfileState(state, action: PayloadAction<IProfileUpdate>) {
      state.isProfileStateLoading = true;
    },
    setIsProfileLoading(state, action: PayloadAction<boolean>) {
      state.isProfileStateLoading = action.payload;
    },
    getApiKey(state, action: PayloadAction<string>) {
      state.isKeyLoading = true;
    },
    setApiKey(state, action: PayloadAction<IAPIKeyState>) {
      state.apiKey = action.payload;
    },
    setIsKeyLoading(state, action: PayloadAction<boolean>) {
      state.isKeyLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.apiError = action.payload;
    },
  },
});

export const { actions: userProfileActions } = slice;
export const useUserProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userProfileSaga });
  return { actions: slice.actions };
};
export const userProfileReducers = slice.reducer;
