import { IVariants, IDefaultTitle, IDefaultDescription } from './types';

export const VARIANTS: IVariants = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  Error: 'error',
  DEFAULT: 'default',
};

export const DEFAULT_TITLE: IDefaultTitle = {
  Success: 'Success!',
  Warning: 'Warning!',
  Info: 'Hi there!',
  Fail: 'Oh snap!',
};

export const DEFAULT_DESCRIPTION: IDefaultDescription = {
  Success: 'Your request has been successfully done.',
  Warning: 'Sorry! There was a problem with your request.',
  Info: 'This is a feature used to ...',
  Error: 'Change a few things up and try submitting again.',
  LOGIN_ERROR:
    'Email and/or Password is incorrect.  Please try again or contact admin at hello@arkenacoffee.com',
  Login_success: 'Login successful.  Welcome',
};
