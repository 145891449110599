import React, { useState } from 'react';
import { Button } from 'app/components/Button';
import { Fail, Done, ProdImg } from 'assets/images';
import { useDispatch } from 'react-redux';
import { IProductModel } from 'app/Model/products';
import { useProductSlice } from 'app/pages/HomePage/slice';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { popUpModal } from '../OpenModal';
import { PopUpModalProps } from '../types/PopUpModalTypes';

const banner = require('assets/images/banner.jpg');

interface IProps {
  className?: string;
  product: IProductModel;
  isProductLoading?: boolean;
  modalOpen: boolean;
}

function isValidImage(url, callback) {
  const img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export default function RenderProduct(props: IProps) {
  const [useProductImage, setUseProductImage] = useState(false);
  if (props.product.image) {
    isValidImage(props.product.image, isValid => {
      if (isValid) {
        setUseProductImage(true);
      }
    });
  }

  const { actions } = useProductSlice();
  const { user } = useAuth0();
  const subscriptionStatus = props.product!.subscribed
    ? 'subscribed'
    : 'unsubscribed';
  const dispatch = useDispatch();
  const isSubscribed = subscriptionStatus !== 'unsubscribed';

  const handleSubscription = () => {
    if (!isSubscribed) {
      dispatch(
        actions.subscribeToProduct({
          priceId: props.product?.prices?.[0].id!,
          userId: user?.sub!,
        }),
      );
    }
  };

  const handleClose = () => {
    setmodelOpenState(false);
  };

  const handleClickOpen = () => {
    setmodelOpenState(true);
  };

  const [modelOpenState, setmodelOpenState] = useState(false);

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  interface Feature {
    feature: string;
    available: boolean;
  }

  const unit_amount = props.product?.prices?.[0]?.unit_amount || 0;

  const subscriptionModalContent: PopUpModalProps = {
    open: modelOpenState,
    content: {
      title: isSubscribed ? 'Unsubscribe' : 'Subscribe',
      description: isSubscribed
        ? 'Are you sure you want to unsubscribe?'
        : 'Do you want to make a subscription?',
    },
    handleClose: handleClose,
    handleAction: handleSubscription,
    actionName: 'Yes',
  };

  const getFeatures: (details?: string) => Array<Feature> = (
    details?: string,
  ) => {
    if (!details) return [];
    let features: Array<Feature> = [];
    try {
      features = JSON.parse(details);
    } catch (e) {
      console.log(e);
      features = [];
    }
    return features;
  };

  return (
    <div>
      {popUpModal(subscriptionModalContent)}
      <div
        className={`gap-y-4 py-6 lg:gap-y-8 lg:pt-8 w-full h-full border-2 border-[#1d2838] rounded-2xl flex flex-col items-start justify-center px-8 bg-[#11161f] ${props.className}`}
      >
        <div className="w-full flex flex-grow flex-row justify-start items-center">
          <div className="w-3/5 max-w-[80px] sm:w-2/5 rounded-md overflow-hidden">
            <img
              src={useProductImage ? props.product.image : ProdImg}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-2xl sm:text-xl md:text-2xl font-bold text-[#f8f8f8] ml-4">
            {props.product?.title}
          </div>
        </div>
        <div className="text-xs xl:text-sm leading-7 text-[#f0f0f0]">
          {props.product?.description}
        </div>
        <div className="font-bold text-2xl lg:text-3xl xl:text-4xl text-[#f0f0f0]">
          {USDollar.format(Number(unit_amount / 100))}
          <span className="text-base text-[#989a9f]">/month</span>
        </div>
        <div>
          <div className="flex flex-col items-start justify-start gap-y-3 text-white">
            {getFeatures(props.product?.details).map((feature, index) => (
              <div
                key={index}
                className="flex flex-row items-center justify-start gap-x-2 md:gap-x-3"
              >
                <div
                  className={`${
                    feature.available ? 'w-3 h-3' : 'w-2 h-2 -mt-1'
                  }`}
                >
                  <img
                    src={feature.available ? Done : Fail}
                    alt="w-full h-full"
                  />
                </div>
                <div
                  className={`text-sm xl:text-base ${
                    feature.available
                      ? 'text-[#d1d1d1] font-bold'
                      : 'text-[#7a7f8e] font-medium'
                  }`}
                >
                  {feature.feature}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={"w-full flex flex-row items-center justify-center gap-x-4"}
        >
          <Button
            type="filled"
            className={`w-3/5 text-xs lg:text-sm py-3 text-[#f0f0f0]`}
            onClick={handleClickOpen}
            disabled={isSubscribed}
          >
            {isSubscribed ? "Already Subscribed" : "Subscribe"}
          </Button>
        </div>
      </div>
    </div>
  );
}
