import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_ROUTE } from 'utils/constants';
import { jwtDecode } from 'jwt-decode';

class HttpClient {
  private instance: AxiosInstance;
  private tokenGenerator: (() => Promise<string>) | null;

  constructor() {
    this.instance = axios.create({ baseURL: API_ROUTE });

    this.instance.interceptors.request.use(
      async (config: any) => {
        let token = localStorage.getItem('token');

        if (token) {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp) {
            const expiryTime = decodedToken.exp * 1000;
            if (expiryTime < Date.now()) {
              localStorage.removeItem('token');
            }
          }
        } else {
          token = await this.fetchToken();
          localStorage.setItem('token', token);
        }

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      error => {
        return Promise.reject(error);
      },
    );

    this.tokenGenerator = null;
  }

  public setTokenGenerator(tokenGenerator: () => Promise<string>): void {
    this.tokenGenerator = tokenGenerator;
  }

  private fetchToken(): Promise<string> {
    if (!this.tokenGenerator) {
      throw new Error('Token generator not set');
    }
    return this.tokenGenerator();
  }

  public async makeRequest<T = any>(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.request<T>(config);
      return response;
    } catch (error) {
      throw new Error(`Request failed: ${error}`);
    }
  }

  public getInstance(): AxiosInstance {
    return this.instance;
  }
}

export const httpClient = new HttpClient();
