/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { defaultLayoutSaga } from './saga';
import { IDefaultLayoutState, IToastMessageConfig } from './type';
import { IUserModel } from 'app/Model/user';

export const initialState: IDefaultLayoutState = {};
const defaultLayoutSlice = createSlice({
  name: 'defaultLayoutState',
  initialState,
  reducers: {
    setAppMessage(state, action: PayloadAction<IToastMessageConfig>) {
      state.appMessage = action.payload;
    },
    hideAppMessage(state) {
      state.appMessage = undefined;
    },
    setUser(state, action: PayloadAction<IUserModel>) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = undefined;
    },
  },
});

export const { actions: defaultLayoutActions } = defaultLayoutSlice;

export const useDefaultLayoutSlice = () => {
  useInjectReducer({
    key: defaultLayoutSlice.name,
    reducer: defaultLayoutSlice.reducer,
  });
  useInjectSaga({ key: defaultLayoutSlice.name, saga: defaultLayoutSaga });
  return { actions: defaultLayoutSlice.actions };
};
