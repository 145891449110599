import React from 'react';
import lottie404 from 'assets/lotties/404.json';
import Lottie from 'react-lottie';
import { Button } from 'app/components/Button';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../MainLayout';

export const PageNotFound = () => {
  const navigate = useNavigate();
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie404,
    renderSettings: {
      preserveAspectRation: 'xMidYMid slice',
    },
  };
  return (
    <MainLayout>
      <div className="flex flex-col items-center justify-center h-screen ">
        <div className="w-1/3 flex flex-col">
          <Lottie options={lottieDefaultOptions} width="100%" />
        </div>
        <div>
          <Button
            type="custom"
            className="border-2 border-[#4a3d8a] bg-[#383064] hover:bg-[#11161f88] text-[#f8f8f8] py-4 px-10 rounded-md"
            onClick={() => navigate('/home')}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};
