import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { SubscriptionsState } from './types';

const selectSlice = (state: RootState) => state?.subscriptions || initialState;

export const selectSubscriptions: (state: RootState) => SubscriptionsState =
  createSelector([selectSlice], state => state);

export const selectSubscriptionPagination = createSelector(
  [selectSubscriptions],
  state => state.subscriptionPagination,
);

export const selectAllSubscriptions = createSelector(
  [selectSubscriptions],
  state => state.subscriptions,
);

export const selectAllInvoices = createSelector(
  [selectSubscriptions],
  state => state.invoices,
);

export const selectIsSubscriptionLoadMoreDisabled = createSelector(
  [selectSubscriptions],
  state => {
    const { subscriptionPagination, isSubLoading } = state;
    const isTotalLimit =
      subscriptionPagination?.limit &&
      subscriptionPagination?.offset &&
      subscriptionPagination?.total
        ? subscriptionPagination?.limit * subscriptionPagination?.offset >=
          subscriptionPagination?.total
        : true;
    return isTotalLimit || isSubLoading;
  },
);

export const selectGettingSubscriptions = createSelector(
  [selectSubscriptions],
  state => state.isSubLoading,
);

export const selectApiKey = createSelector(
  [selectSubscriptions],
  state => state.apiKey,
);

export const selectGettingApiKey = createSelector(
  [selectSubscriptions],
  state => state.isKeyLoading,
);
