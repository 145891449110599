import { Button } from 'app/components/Button';
import { PopUpModalProps } from 'app/components/types/PopUpModalTypes';

export const popUpModal = ({
  open,
  content,
  handleClose,
  handleAction,
  actionName,
}: PopUpModalProps) => {
  return open ? (
    <div className="z-20 fixed top-0 left-0 w-full h-full bg-[#0e121a80]">
      <div className="w-full h-full flex flex-row items-center justify-center p-4">
        <div className="flex flex-col items-start justify-center bg-[#fafafa] rounded-2xl p-4 lg:p-8">
          <div className="text-lg lg:text-2xl text-[#383064] font-bold">
            {content.title}
          </div>
          <div className="text-sm lg:text-base text-[#202020] p-6 lg:p-8 font-normal">
            {content.description}
          </div>
          <div className="w-full flex flex-row items-center justify-end">
            <Button
              type="custom"
              className="bg-[#f0f0f0] text-[#7c7c7c] hover:bg-[#f3f3f3] rounded-md px-6 py-2 text-base font-normal"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              type="custom"
              className="ml-4 bg-[#383064] text-[#fafafa] hover:bg-[#726d91] rounded-md px-8 py-2 text-base font-normal"
              onClick={() => {
                handleAction();
              }}
            >
              {actionName}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
