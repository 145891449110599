import React, { useState, useEffect } from 'react';
import { Button } from 'app/components/Button';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { ISubscription } from 'app/Model/subscription';
import { useSubscriptionSlice } from 'app/pages/SubscriptionPage/slice';
import { Link, useNavigate } from 'react-router-dom';
import { popUpModal } from 'app/components/OpenModal';
import { PopUpModalProps } from 'app/components/types/PopUpModalTypes';

const banner = require('assets/images/banner.jpg');

interface IProps {
  subscription: ISubscription;
  isSubLoading: boolean;
}

function isValidImage(url, callback) {
  const img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export default function RenderSubscription(props: IProps) {
  const [useProductImage, setUseProductImage] = useState(false);
  if (props.subscription.price.product.image) {
    isValidImage(props.subscription.price.product.image, isValid => {
      if (isValid) {
        setUseProductImage(true);
      }
    });
  }
  const { actions: subscriptionActions } = useSubscriptionSlice();
  const [modalState, setModalState] = React.useState(false);
  const subscriptionStatus =
    props.subscription.status == 'active' ? 'subscribed' : 'unsubscribed';

  const isSubscribed = subscriptionStatus !== 'unsubscribed';

  const navigate = useNavigate();

  const { user } = useAuth0();
  const manageAccounts =
    props.subscription.status == 'past_due' ||
    props.subscription.status == 'unpaid' ||
    props.subscription.status == 'incomplete';
  const dispatch = useDispatch();

  const handleCancelSubscription = () => {
    dispatch(subscriptionActions.cancelSubscription(props.subscription.id));
  };
  const manageSubscription = () => {
    if (manageAccounts) {
      dispatch(subscriptionActions.createCustomer(user?.sub!));
    }
  };

  const handleClose = () => {
    setModalState(false);
  };

  const handleClickOpen = () => {
    setModalState(true);
  };
  const cancelSubscriptionModalContent: PopUpModalProps = {
    open: modalState,
    content: {
      title: 'Cancel Subscription',
      description: 'Are you sure you want to cancel your subscription?',
    },
    handleClose: handleClose,
    handleAction: handleCancelSubscription,
    actionName: 'Yes',
  };
  return (
    <div>
      {popUpModal(cancelSubscriptionModalContent)}
      <div className="gap-y-4 w-full py-6 px-6 bg-[#11161f] border border-[#775dda] px-4 text-gray-800 rounded-2xl relative flex flex-col items-center justify-center hover:bg-[#161e2b]">
        <div className="w-full flex flex-grow flex-row justify-start items-center mt-5">
          <div className="w-2/5 rounded-md overflow-hidden">
            <img
              src={
                useProductImage
                  ? props.subscription.price.product.image
                  : banner
              }
              className="w-full h-full object-cover"
            />
          </div>
          <div className="md:text-2xl font-bold text-[#f8f8f8] ml-4">
            {props.subscription?.price.product?.title}
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-end gap-5 absolute top-4 right-4">
          <div className="flex flex-row items-center">
            <div className="text-white text-sm font-medium">
              {props.subscription.cancel_at_period_end
                ? 'Expiring'
                : props.subscription?.status
                    .replaceAll('_', ' ')
                    .replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substring(1).toLowerCase()
                      );
                    })}
            </div>
          </div>
          <div
            className={`w-[20px] h-[20px] rounded-full ${
              props.subscription?.status === 'past_due' ||
              props.subscription?.status === 'unpaid' ||
              props.subscription?.status === 'incomplete' ||
              props.subscription.cancel_at_period_end
                ? 'bg-[#ab530a]'
                : props.subscription?.status === 'active'
                ? 'bg-[#298d2e]'
                : 'bg-[#99282a]'
            }`}
          />
        </div>
        <div className="gap-y-4 w-full flex flex-col px-2">
          <div className="text-[#f8f8f8] text-xl font-normal">
            Billed{' '}
            {props.subscription?.price.recurring_interval == 'month'
              ? 'Monthly'
              : 'Yearly'}
          </div>
          <div className="text-[#f8f8f8] text-2xl lg:text-3xl xl:text-4xl font-bold">
            ${props.subscription?.price.unit_amount / 100}
            <span className="text-base text-[#989a9f]">/month</span>
          </div>
          <div className="text-sm font-normal text-[#827c7c]">
            {props.subscription.cancel_at_period_end
              ? 'Expires on'
              : 'Renews on'}{' '}
            {new Date(props.subscription?.expires).toDateString()}
            {}
          </div>
        </div>
        {props.subscription?.status == 'past_due' ||
        props.subscription?.status == 'unpaid' ||
        props.subscription?.status == 'incomplete' ? (
          <div className="w-full flex flex-row items-center justify-center gap-2">
            <Button
              className="w-2/5 py-3 text-[#f8f8f8]"
              type="unfilled"
              onClick={manageSubscription}
            >
              Manage
            </Button>
            <Button
              className="w-3/5 py-3 text-[#f8f8f8]"
              type="filled"
              onClick={() => {
                setModalState(true);
              }}
            >
              Cancel
            </Button>
          </div>
        ) : props.subscription.cancel_at_period_end ? (
          <div className="w-full flex flex-row items-center justify-center gap-2">
            <Button
              className="w-full py-3"
              type="unfilled"
              onClick={() => window.open('https://app.sov.ai', '_blank')}
            >
              <span style={{ color: '#f8f8f8' }}>App</span>
            </Button>
          </div>
        ) : props.subscription?.status == 'active' ? (
          <div className="w-full flex flex-row items-center justify-center gap-2">
            <Link
              className="w-2/5"
              style={{ color: '#f8f8f8', textDecoration: 'none' }}
              to="https://app.sov.ai"
              target="_blank"
            >
              <Button className="w-full h-full py-3" type="unfilled">
                App
              </Button>
            </Link>
            <Button
              className="w-3/5 py-3 text-[#f8f8f8]"
              type="filled"
              onClick={handleClickOpen}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
